import CommentsActions from "actions/CommentsActions";
import DataConstants from "const/DataConstants";
import DocumentsActions from "actions/DocumentsActions";
import Utils from "utils/Utils";

const { COMMENT_TARGET_TYPES } = DataConstants;

const {
  FETCH_DOCUMENT_START,
  FETCH_DOCUMENTS_LIST_START,
  ADD_NEW_DOCUMENT_START,
  EDIT_DOCUMENT_START,
  DELETE_DOCUMENT_START,
  BULK_DOCUMENTS_UPDATE_START,
  FETCH_DOCUMENT_DONE,
  FETCH_DOCUMENTS_LIST_DONE,
  IMPORT_CLOUD_DOCUMENT_DONE,
  UPLOAD_DOCUMENTS_START,
  UPLOAD_DOCUMENT_DONE,
  ADD_NEW_DOCUMENT_DONE,
  EDIT_DOCUMENT_DONE,
  DELETE_DOCUMENT_DONE,
  BULK_DOCUMENTS_UPDATE_DONE,
  EXPORT_ALL_DOCUMENTS_DONE,
  FETCH_DOCUMENT_ERROR,
  FETCH_DOCUMENTS_LIST_ERROR,
  IMPORT_CLOUD_DOCUMENT_ERROR,
  UPLOAD_DOCUMENT_ERROR,
  ADD_NEW_DOCUMENT_ERROR,
  EDIT_DOCUMENT_ERROR,
  DELETE_DOCUMENT_ERROR,
  BULK_DOCUMENTS_UPDATE_ERROR,
  EXPORT_ALL_DOCUMENTS_ERROR,
  LOCK_DOCUMENT,
  UNLOCK_DOCUMENT
} = DocumentsActions;

const { MARK_ALL_AS_READ_DONE, ADD_NEW_COMMENT_START, FETCH_COMMENTS_DONE } = CommentsActions;

const initialState = {
  fetchingData: false,
  dataHash: null,
  uploadingCount: 0,
  stats: {},
  data: [],
  locked: {}
};

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case FETCH_DOCUMENTS_LIST_START:
      return {
        ...state,
        ...(payload.clearList ? { data: [], stats: {}, dataHash: null } : {}),
        ...(payload.backgroundUpdate ? {} : { fetchingData: true })
      };

    case FETCH_DOCUMENT_START:
    case ADD_NEW_DOCUMENT_START:
    case EDIT_DOCUMENT_START:
    case DELETE_DOCUMENT_START:
    case BULK_DOCUMENTS_UPDATE_START:
      return {
        ...state,
        ...(payload?.backgroundUpdate ? {} : { fetchingData: true })
      };

    case FETCH_DOCUMENTS_LIST_DONE:
      return {
        ...state,
        ...(!payload.dataHash || payload.dataHash !== state.dataHash ? {
          dataHash: payload.dataHash || null,
          data: payload.documents,
          stats: {
            ...Object.fromEntries(Object.entries(payload.stats).map(([key, value]) => [key, value.count])),
            current: (payload.status && !Array.isArray(payload.status)
              ? payload.stats[payload.status] : payload.stats.total).count
          }
        } : {}),
        ...(payload.backgroundUpdate ? {} : { fetchingData: false })
      };

    case UPLOAD_DOCUMENTS_START:
      return {
        ...state,
        uploadingCount: (state.uploadingCount || 0) + payload.documentsCount,
        fetchingData: !payload.backgroundUpdate
      };

    case EDIT_DOCUMENT_DONE: {
      return {
        ...state,
        data: state.data.map((item) => {
          return item.id === payload.document.id ? payload.document : item;
        }),
        fetchingData: false
      };
    }
    case DELETE_DOCUMENT_DONE: {
      return {
        ...state,
        data: state.data.filter((item) => item.id !== payload.documentId),
        fetchingData: false
      };
    }
    case BULK_DOCUMENTS_UPDATE_DONE: {
      return {
        ...state,
        data: payload.action === DataConstants.BULK_ACTIONS.REMOVE
          ? state.data.filter(({ id }) => !payload.documentsIds.includes(id))
          : state.data,
        fetchingData: false
      };
    }
    case FETCH_DOCUMENT_DONE:
    case ADD_NEW_DOCUMENT_DONE:
    case EXPORT_ALL_DOCUMENTS_DONE:
    case FETCH_DOCUMENT_ERROR:
    case FETCH_DOCUMENTS_LIST_ERROR:
    case ADD_NEW_DOCUMENT_ERROR:
    case EDIT_DOCUMENT_ERROR:
    case DELETE_DOCUMENT_ERROR:
    case BULK_DOCUMENTS_UPDATE_ERROR:
    case EXPORT_ALL_DOCUMENTS_ERROR:
      return { ...state, fetchingData: false };

    case IMPORT_CLOUD_DOCUMENT_DONE:
    case UPLOAD_DOCUMENT_DONE:
    case IMPORT_CLOUD_DOCUMENT_ERROR:
    case UPLOAD_DOCUMENT_ERROR:
      return { ...state, uploadingCount: state.uploadingCount - 1, fetchingData: false };

    case MARK_ALL_AS_READ_DONE:
      return {
        ...state,
        data: state.data.map((item) => ({
          ...item,
          comments: item.comments && { ...item.comments, unread: 0 }
        }))
      };

    case ADD_NEW_COMMENT_START: {
      const { targetType, targetId, text, createdBy } = payload;

      if (targetType !== COMMENT_TARGET_TYPES.DOCUMENTS) return state;

      return {
        ...state,
        data: Utils.arrayUpdateItemById(state.data, targetId, (item) => {
          const { all = 0, ...rest } = item.comments || {};

          return {
            ...item,
            comments: { ...rest, all: all + 1 },
            lastComment: { text, createdBy }
          };
        })
      };
    }

    case FETCH_COMMENTS_DONE: {
      const { targetType, targetId } = payload;

      if (targetType !== COMMENT_TARGET_TYPES.DOCUMENTS) return state;

      return {
        ...state,
        data: Utils.arrayUpdateItemById(state.data, targetId, (item) => {
          return { ...item, comments: { ...item.comments, unread: 0 } };
        })
      };
    }

    case LOCK_DOCUMENT:
      return { ...state, locked: { ...state.locked, [payload]: true } };

    case UNLOCK_DOCUMENT:
      return { ...state, locked: { ...state.locked, [payload]: false } };

    default:
      return state;
  }
};
