import ArchiveActions from "actions/ArchiveActions";
import AuditActions from "actions/AuditActions";
import BusinessesActions from "actions/BusinessesActions";
import CommentsActions from "actions/CommentsActions";
import DocumentsActions from "actions/DocumentsActions";
import IntegrationsActions from "actions/IntegrationsActions";
import TransactionsActions from "actions/TransactionsActions";
import Utils from "utils/Utils";

const {
  SELECT_BUSINESS,
  FETCH_BUSINESS_START,
  FETCH_BUSINESSES_LIST_START,
  FETCH_DATA_MATCHES_LIST_START,
  FETCH_GLOBAL_STATS_START,
  ADD_NEW_BUSINESS_START,
  PAIR_MATCHES_START,
  UNPAIR_MATCHES_START,
  EDIT_BUSINESS_SETTINGS_START,
  EDIT_BUSINESS_AUDIT_SETTINGS_START,
  DELETE_BUSINESS_START,
  FETCH_BUSINESS_DONE,
  FETCH_BUSINESSES_LIST_DONE,
  FETCH_DATA_MATCHES_LIST_DONE,
  FETCH_GLOBAL_STATS_DONE,
  PAIR_MATCHES_DONE,
  UNPAIR_MATCHES_DONE,
  ADD_NEW_BUSINESS_DONE,
  EDIT_BUSINESS_SETTINGS_DONE,
  EDIT_BUSINESS_AUDIT_SETTINGS_DONE,
  DELETE_BUSINESS_DONE,
  FETCH_BUSINESS_ERROR,
  FETCH_BUSINESSES_LIST_ERROR,
  FETCH_DATA_MATCHES_LIST_ERROR,
  FETCH_GLOBAL_STATS_ERROR,
  ADD_NEW_BUSINESS_ERROR,
  EDIT_BUSINESS_SETTINGS_ERROR,
  EDIT_BUSINESS_AUDIT_SETTINGS_ERROR,
  DELETE_BUSINESS_ERROR
} = BusinessesActions;

const { INTEGRATE_BUSINESSES_DONE } = IntegrationsActions;

const { MARK_ALL_AS_READ_DONE } = CommentsActions;

const { FETCH_TRANSACTIONS_LIST_DONE } = TransactionsActions;

const { FETCH_DOCUMENTS_LIST_DONE } = DocumentsActions;

const { MARK_AS_READ } = ArchiveActions;

const { FETCH_AUDIT_LIST_DONE } = AuditActions;

const initialState = {
  fetchingData: false,
  fetchingMatches: false,
  fetchingGlobalStats: false,
  selectedBusinessId: null,
  globalStats: {},
  matches: [],
  data: [],
  linkExpirationsInfo: []
};

const checkFiltersUsed = ({ filters }) => {
  return !!(filters && Object.values(filters).filter((value) => value).length);
};

const resetComments = (entity) => {
  return Utils.objectMap(entity, ({ byMonth, ...rest }) => {
    return { ...rest, byMonth: Utils.objectMap(byMonth, (month) => {
      return { ...month, comments: 0 };
    }) };
  });
};

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case SELECT_BUSINESS:
      return { ...state, selectedBusinessId: payload.businessId };

    case FETCH_BUSINESS_START:
      return { ...state, fetchingData: !payload.backgroundUpdate };

    case FETCH_BUSINESSES_LIST_START:
      return {
        ...state,
        data: payload.clearList ? [] : state.data,
        fetchingData: !payload.backgroundUpdate
      };

    case ADD_NEW_BUSINESS_START:
    case EDIT_BUSINESS_SETTINGS_START:
    case EDIT_BUSINESS_AUDIT_SETTINGS_START:
    case DELETE_BUSINESS_START:
      return { ...state, fetchingData: true };

    case FETCH_DATA_MATCHES_LIST_START:
      return {
        ...state,
        matches: [],
        fetchingMatches: !payload.backgroundUpdate
      };

    case FETCH_GLOBAL_STATS_START:
      return { ...state, ...(payload.clearData ? { globalStats: {} } : {}), fetchingGlobalStats: true };

    case PAIR_MATCHES_START:
    case UNPAIR_MATCHES_START:
      return { ...state, fetchingMatches: !payload.backgroundUpdate };

    case FETCH_BUSINESS_DONE: {
      const index = state.data.findIndex(({ id }) => id === payload.business.id);

      const newData = [...state.data];

      newData[index] = { ...newData[index], ...payload.business };

      return { ...state, data: newData, fetchingData: false };
    }
    case FETCH_BUSINESSES_LIST_DONE:
      return {
        ...state,
        fetchingData: false,
        data: payload.businesses
      };

    case FETCH_DATA_MATCHES_LIST_DONE:
      return { ...state, matches: payload.matches, fetchingMatches: false };

    case FETCH_GLOBAL_STATS_DONE:
      return { ...state, globalStats: payload.globalStats, fetchingGlobalStats: false };

    case FETCH_TRANSACTIONS_LIST_DONE:
      if (checkFiltersUsed(payload)) return state;

      return { ...state, globalStats: { ...state.globalStats, transactions: payload.stats } };

    case FETCH_DOCUMENTS_LIST_DONE:
      if (checkFiltersUsed(payload)) return state;

      return { ...state, globalStats: { ...state.globalStats, documents: payload.stats } };

    case FETCH_AUDIT_LIST_DONE:
      if (checkFiltersUsed(payload)) return state;

      return { ...state, globalStats: { ...state.globalStats, audit: payload.stats } };

    case PAIR_MATCHES_DONE:
    case UNPAIR_MATCHES_DONE:
      return { ...state, fetchingMatches: false };

    case ADD_NEW_BUSINESS_DONE:
      return { ...state, data: [...state.data, payload.business], fetchingData: false };

    case INTEGRATE_BUSINESSES_DONE:
      return { ...state, data: [...state.data, ...payload.businesses] };

    case EDIT_BUSINESS_SETTINGS_DONE: {
      const index = state.data.findIndex(({ id }) => id === payload.id);

      const newData = [...state.data];

      newData[index] = { ...newData[index], settings: { ...newData[index].settings, ...payload.settings } };

      return { ...state, data: newData, fetchingData: false };
    }
    case EDIT_BUSINESS_AUDIT_SETTINGS_DONE: {
      const { id, auditSettings } = payload;

      return {
        ...state,
        data: Utils.arrayUpdateItemById(state.data, id, (business) => ({ ...business, auditSettings })),
        fetchingData: false
      };
    }
    case DELETE_BUSINESS_DONE:
      return { ...state, data: state.data.filter(({ id }) => id !== payload.businessId), fetchingData: false };

    case MARK_AS_READ: {
      const { globalStats: { archive: { notRead = 1 } = {} } } = state;

      return {
        ...state,
        globalStats: {
          ...state.globalStats,
          archive: { notRead: notRead - 1 }
        }
      };
    }
    case FETCH_BUSINESS_ERROR:
    case FETCH_BUSINESSES_LIST_ERROR:
    case ADD_NEW_BUSINESS_ERROR:
    case EDIT_BUSINESS_SETTINGS_ERROR:
    case EDIT_BUSINESS_AUDIT_SETTINGS_ERROR:
    case DELETE_BUSINESS_ERROR:
      return { ...state, fetchingData: false };

    case FETCH_DATA_MATCHES_LIST_ERROR:
      return { ...state, fetchingMatches: false };

    case FETCH_GLOBAL_STATS_ERROR:
      return { ...state, fetchingGlobalStats: false };

    case MARK_ALL_AS_READ_DONE: {
      const { globalStats } = state;

      return {
        ...state,
        globalStats: {
          ...globalStats,
          audit: resetComments(globalStats.audit),
          transactions: resetComments(globalStats.transactions)
        }
      };
    }

    default:
      return state;
  }
};
