export default class PricingPlans {
  static BASIC = "basic";

  static SMART = "smart";

  static PREMIUM = "premium";

  static PLANS = [
    PricingPlans.BASIC,
    PricingPlans.SMART,
    PricingPlans.PREMIUM
  ];

  static getLevel(plan) {
    return PricingPlans.PLANS.indexOf(plan);
  }

  static checkIsAllowed(neededPlan, currentPlan) {
    return PricingPlans.getLevel(neededPlan) <= PricingPlans.getLevel(currentPlan);
  }
}
