export default class TaskFilters {
  static ALL_TASKS = "allTasks";

  static ALL_FOR_BUSINESS = "allForBusiness";

  static ALL_FOR_ACCOUNTANT = "allForAccountant";

  static ALL_ACTIVE_TASKS = "allActiveTasks";

  static ACTIVE_FOR_BUSINESS = "activeForBusiness";

  static ACTIVE_FOR_ACCOUNTANT= "activeForAccountant";
}
