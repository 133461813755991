import { getPreloaderText } from "selectors/ui";
import { useSelector } from "react-redux";
import React from "react";
import UiPreloader from "nlib/ui/Preloader";

const Preloader = ({ children, ...restProps }) => {
  const preloaderText = useSelector(getPreloaderText);

  return <UiPreloader {...restProps}>{preloaderText || children}</UiPreloader>;
};

export default React.memo(Preloader);
