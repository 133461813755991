import { createSelector } from "reselect";
import { getActiveUsersData } from "selectors/organizations";
import { getUserData } from "selectors/user";
import moment from "moment";

export const checkTasksFetching = ({ tasks: { fetchingData } }) => fetchingData;

export const getTasksData = createSelector(
  [
    ({ tasks: { data } }) => data,
    getActiveUsersData,
    getUserData
  ],
  (data, users, user) => {
    return data.map(({ createdBy, targetUser, ...restData }) => {
      const createdByUserData = users.find(({ id }) => id === createdBy) || null;

      const targetUserData = users.find(({ id }) => id === targetUser) || null;

      return { ...restData, createdBy: createdByUserData, targetUser: targetUserData };
    }).sort((
      { targetUser: targetUserA, completed: completedA, dueDate: dueDateA = moment.unix(0) },
      { targetUser: targetUserB, completed: completedB, dueDate: dueDateB = moment.unix(0) }
    ) => {
      const ruleA = Number(!!completedA) - Number(!!completedB);

      const ruleB = Number(((targetUserB && targetUserB.id) || "") === user.id)
        - Number(((targetUserA && targetUserA.id) || "") === user.id);

      const ruleC = moment.utc(dueDateA).diff(moment.utc(dueDateB));

      return ruleA || ruleB || ruleC;
    });
  }
);

export const getActiveTasksData = createSelector(
  [
    getTasksData,
    getUserData
  ],
  (tasks, user) => {
    return tasks.filter(({ targetUser, completed }) => targetUser?.id === user.id && !completed);
  }
);
