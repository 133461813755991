/* eslint-disable no-magic-numbers */
import quickBooks from "assets/quickBooks.svg";

import quickBooksDesktop from "assets/quickBooksDesktop.svg";

import xero from "assets/xero.svg";

import zoho from "assets/zoho.svg";

import { memoize } from "decko";
import PricingPlans from "const/PricingPlans";

export default class IntegrationServices {
  static ABRA_FLEXI_BEE = { label: "Abra FlexiBee", value: "abraFlexiBee", documentsOnlyExport: true, showExportSettings: true };

  static MONEY_S_THREE = { label: "Money S3", value: "s3money" };

  static POHODA = { label: "Pohoda", value: "pohoda" };

  static QUICK_BOOKS_DESKTOP = {
    label: "QuickBooks Desktop",
    shortLabel: "QuickBooks Desktop",
    value: "quickBooksDesktop",
    logo: quickBooksDesktop,
    offlineIntegration: true,
    fullIntegration: true,
    pricingPlan: PricingPlans.PREMIUM
  };

  static QUICK_BOOKS = {
    label: "QuickBooks Online",
    shortLabel: "QuickBooks",
    value: "quickBooks",
    logo: quickBooks,
    fullIntegration: true,
    availableForRpa: true,
    pricingPlan: PricingPlans.PREMIUM
  };

  static XERO = {
    label: "Xero",
    shortLabel: "Xero",
    value: "xero",
    logo: xero,
    fullIntegration: true,
    availableForRpa: true,
    pricingPlan: PricingPlans.PREMIUM
  };

  static ZOHO = {
    label: "Zoho Books",
    shortLabel: "Zoho",
    value: "zoho",
    logo: zoho,
    fullIntegration: true,
    manualSelection: true,
    pricingPlan: PricingPlans.PREMIUM
  };

  @memoize
  static getList() {
    return Object.values(IntegrationServices);
  }

  @memoize
  static getFullIntegratedList() {
    return Object.values(IntegrationServices).filter((service) => service.fullIntegration);
  }

  @memoize
  static getRpaEnabledList() {
    return Object.values(IntegrationServices).filter((service) => service.availableForRpa);
  }

  @memoize
  static getByValue(value) {
    return IntegrationServices.getList().find((service) => service.value === value);
  }
}
