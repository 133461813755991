import CommentsActions from "actions/CommentsActions";
import DataConstants from "const/DataConstants";
import TasksActions from "actions/TasksActions";
import Utils from "utils/Utils";

const {
  FETCH_TASKS_LIST_START,
  FETCH_TASKS_LIST_DONE,
  FETCH_TASKS_LIST_ERROR,
  FETCH_TASK_START,
  FETCH_TASK_DONE,
  FETCH_TASK_ERROR,
  ADD_NEW_TASK_START,
  ADD_NEW_TASK_DONE,
  ADD_NEW_TASK_ERROR,
  EDIT_TASK_START,
  EDIT_TASK_DONE,
  EDIT_TASK_ERROR,
  DELETE_TASK_START,
  DELETE_TASK_DONE,
  DELETE_TASK_ERROR,
  CLEAR_TASKS
} = TasksActions;

const { ADD_NEW_COMMENT_START, MARK_ALL_AS_READ_DONE } = CommentsActions;

const { COMMENT_TARGET_TYPES } = DataConstants;

const initialState = {
  fetchingData: false,
  dataHash: null,
  data: [],
  active: []
};

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case FETCH_TASKS_LIST_START:
      return {
        ...state,
        ...(payload.clearList ? { data: [], dataHash: null } : {}),
        fetchingData: !payload.backgroundUpdate
      };

    case FETCH_TASK_START:
    case ADD_NEW_TASK_START:
    case EDIT_TASK_START:
    case DELETE_TASK_START:
      return { ...state, fetchingData: true };

    case ADD_NEW_COMMENT_START: {
      const { targetType, targetId } = payload;

      if (targetType !== COMMENT_TARGET_TYPES.TASKS) return state;

      return {
        ...state,
        data: Utils.arrayUpdateItemById(state.data, targetId, (task) => {
          return { ...task, comments: { ...task.comments, all: task.comments.all + 1 } };
        })
      };
    }

    case FETCH_TASKS_LIST_DONE: {
      const newData = payload.dataHash === state.dataHash
        ? state.data
        : (payload.editableTaskId
          ? payload.tasks.map((task) => {
            if (task.id === payload.editableTaskId) {
              return {
                ...state.data.find(({ id }) => id === task.id),
                ...task
              };
            }

            return task;
          })
          : payload.tasks);

      return { ...state, fetchingData: false, data: newData, dataHash: payload.dataHash };
    }

    case ADD_NEW_TASK_DONE:
      return { ...state, fetchingData: false };

    case FETCH_TASK_DONE:
    case EDIT_TASK_DONE: {
      return {
        ...state,
        fetchingData: false,
        data: state.data.map((task) => {
          if (task.id === payload.task.id) return { ...task, ...payload.task };

          return task;
        })
      };
    }
    case DELETE_TASK_DONE:
      return { ...state, fetchingData: false, data: state.data.filter(({ id }) => id !== payload.taskId) };

    case FETCH_TASKS_LIST_ERROR:
    case FETCH_TASK_ERROR:
    case ADD_NEW_TASK_ERROR:
    case EDIT_TASK_ERROR:
    case DELETE_TASK_ERROR:
      return { ...state, fetchingData: false };

    case MARK_ALL_AS_READ_DONE:
      return {
        ...state,
        data: state.data.map((item) => ({
          ...item,
          comments: item.comments && { ...item.comments, unread: 0 }
        }))
      };

    case CLEAR_TASKS:
      return {
        fetchingData: false,
        dataHash: null,
        data: []
      };

    default:
      return state;
  }
};
