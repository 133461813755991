export const checkPreloaderShown = ({ ui: { preloaderShown } }) => preloaderShown;

export const checkPreloaderOverlayShown = ({ ui: { preloaderOverlayShown } }) => preloaderOverlayShown;

export const checkModalContentShown = ({ ui: { modalContentShown } }) => modalContentShown;

export const getModalWindowConfig = ({ ui: { modalWindowConfig } }) => modalWindowConfig;

export const getPreloaderText = ({ ui: { preloaderText } }) => preloaderText;

export const getModalImages = ({ ui: { modalImages } }) => modalImages;

export const getTasksFilter = ({ ui: { tasksFilter } }) => tasksFilter;

export const getOpenedModalsData = ({ ui: { openedModals } }) => openedModals;

export const getOpenedCommentsEntityId = ({ ui: { openedCommentsEntityId } }) => openedCommentsEntityId;
