import BusinessesActions from "actions/BusinessesActions";
import CommentsActions from "actions/CommentsActions";
import DataConstants from "const/DataConstants";
import TransactionsActions from "actions/TransactionsActions";
import Utils from "utils/Utils";

const { COMMENT_TARGET_TYPES } = DataConstants;

const {
  FETCH_TRANSACTIONS_LIST_START,
  FETCH_TRANSACTIONS_TAGS_LIST_START,
  FETCH_TRANSACTIONS_REASONS_LIST_START,
  IMPORT_TRANSACTIONS_START,
  ADD_NEW_TRANSACTION_START,
  EDIT_TRANSACTION_START,
  DELETE_TRANSACTION_START,
  BULK_TRANSACTIONS_UPDATE_START,
  FETCH_TRANSACTIONS_LIST_DONE,
  FETCH_TRANSACTIONS_TAGS_LIST_DONE,
  FETCH_TRANSACTIONS_REASONS_LIST_DONE,
  IMPORT_TRANSACTIONS_DONE,
  ADD_NEW_TRANSACTION_DONE,
  EDIT_TRANSACTION_DONE,
  DELETE_TRANSACTION_DONE,
  BULK_TRANSACTIONS_UPDATE_DONE,
  FETCH_TRANSACTIONS_LIST_ERROR,
  FETCH_TRANSACTIONS_TAGS_LIST_ERROR,
  FETCH_TRANSACTIONS_REASONS_LIST_ERROR,
  IMPORT_TRANSACTIONS_ERROR,
  ADD_NEW_TRANSACTION_ERROR,
  EDIT_TRANSACTION_ERROR,
  DELETE_TRANSACTION_ERROR,
  BULK_TRANSACTIONS_UPDATE_ERROR,
  LOCK_TRANSACTION,
  UNLOCK_TRANSACTION
} = TransactionsActions;

const { UNPAIR_MATCHES_DONE } = BusinessesActions;

const { MARK_ALL_AS_READ_DONE, ADD_NEW_COMMENT_START } = CommentsActions;

const initialState = {
  fetchingData: false,
  fetchingTags: false,
  fetchingReasons: false,
  dataHash: null,
  stats: {},
  data: [],
  tags: [],
  reasons: [],
  locked: {}
};

const getStatsState = ({ stats, status: statuses }) => {
  const currentStats = statuses
    ? (Array.isArray(statuses) ? statuses : [statuses]).reduce((result, status) => {
      const item = stats[status];

      if (item?.count) {
        result.count += item.count;
      }

      return result;
    }, { count: 0 })
    : stats.total;

  return {
    stats: {
      ...Object.fromEntries(Object.entries(stats).map(([key, value]) => [key, value.count])),
      current: currentStats.count
    }
  };
};

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case FETCH_TRANSACTIONS_LIST_START:
      return {
        ...state,
        ...(payload.clearList ? { data: [], stats: {}, dataHash: null } : {}),
        ...(payload.backgroundUpdate ? {} : { fetchingData: true })
      };

    case FETCH_TRANSACTIONS_TAGS_LIST_START:
      return { ...state, fetchingTags: true };

    case FETCH_TRANSACTIONS_REASONS_LIST_START:
      return { ...state, fetchingReasons: true };

    case IMPORT_TRANSACTIONS_START:
    case ADD_NEW_TRANSACTION_START:
    case EDIT_TRANSACTION_START:
    case DELETE_TRANSACTION_START:
    case BULK_TRANSACTIONS_UPDATE_START:
      return {
        ...state,
        ...(payload?.backgroundUpdate ? {} : { fetchingData: true })
      };

    case FETCH_TRANSACTIONS_LIST_DONE:

      return {
        ...state,
        ...(!payload.dataHash || payload.dataHash !== state.dataHash ? {
          ...getStatsState(payload),
          data: payload.transactions,
          dataHash: payload.dataHash || null
        } : {}),
        ...(payload.backgroundUpdate ? {} : { fetchingData: false })
      };

    case FETCH_TRANSACTIONS_TAGS_LIST_DONE:
      return { ...state, tags: payload.tags, fetchingTags: false };

    case FETCH_TRANSACTIONS_REASONS_LIST_DONE:
      return { ...state, reasons: payload.reasons, fetchingReasons: false };

    case EDIT_TRANSACTION_DONE: {
      const index = state.data.findIndex(({ id }) => id === payload.transaction.id);

      const newData = [...state.data];

      newData[index] = { ...newData[index], ...payload.transaction };

      return { ...state, data: newData, fetchingData: false };
    }
    case DELETE_TRANSACTION_DONE: {
      const index = state.data.findIndex(({ id }) => id === payload.transactionId);

      const newData = [...state.data];

      newData[index] = { ...newData[index], deleted: true };

      return { ...state, data: newData, fetchingData: false };
    }
    case BULK_TRANSACTIONS_UPDATE_DONE: {
      let newData = null;

      if (payload.action === DataConstants.BULK_ACTIONS.REMOVE || payload.action === DataConstants.BULK_ACTIONS.RESET_AND_REDO) {
        newData = [...state.data];
        payload.transactionIds.forEach((transactionId) => {
          const index = state.data.findIndex(({ id }) => id === transactionId);

          newData[index] = { ...newData[index], deleted: true };
        });
      }

      return { ...state, data: newData || state.data, fetchingData: false };
    }
    case UNPAIR_MATCHES_DONE: {
      const index = state.data.findIndex(({ id }) => id === payload.transactionId);

      if (~index) {
        const newData = [...state.data];

        const { documentId, ...restTransaction } = newData[index];

        newData[index] = restTransaction;

        return { ...state, data: newData };
      }

      return state;
    }
    case ADD_NEW_COMMENT_START: {
      const { targetType, targetId, text, createdBy } = payload;

      if (targetType !== COMMENT_TARGET_TYPES.TRANSACTIONS) return state;

      return {
        ...state,
        data: Utils.arrayUpdateItemById(state.data, targetId, (transaction) => {
          const { all = 0, ...rest } = transaction.comments || {};

          return {
            ...transaction,
            comments: { ...rest, all: all + 1 },
            lastComment: { text, createdBy }
          };
        })
      };
    }
    case IMPORT_TRANSACTIONS_DONE:
    case ADD_NEW_TRANSACTION_DONE:
    case FETCH_TRANSACTIONS_LIST_ERROR:
    case IMPORT_TRANSACTIONS_ERROR:
    case ADD_NEW_TRANSACTION_ERROR:
    case EDIT_TRANSACTION_ERROR:
    case DELETE_TRANSACTION_ERROR:
    case BULK_TRANSACTIONS_UPDATE_ERROR:
      return { ...state, fetchingData: false };

    case FETCH_TRANSACTIONS_TAGS_LIST_ERROR:
      return { ...state, fetchingTags: false };

    case MARK_ALL_AS_READ_DONE:
      return {
        ...state,
        data: state.data.map((item) => ({
          ...item,
          comments: item.comments && { ...item.comments, unread: 0 }
        }))
      };

    case LOCK_TRANSACTION:
      return { ...state, locked: { ...state.locked, [payload]: true } };

    case UNLOCK_TRANSACTION:
      return { ...state, locked: { ...state.locked, [payload]: false } };

    case FETCH_TRANSACTIONS_REASONS_LIST_ERROR:
      return { ...state, fetchingReasons: false };
  }

  return state;
};
